import React from "react"
import { connect } from "react-redux"
import { Control, LocalForm } from "react-redux-form"
import { Form, Col } from "react-bootstrap"

import { expectAuthenticationState } from "../components/authentication"
import Layout from "../components/layout"
import SEO from "../components/seo"

import INTERESTS from "../../../backend/src/users/interests"

import { USAGE_TYPES } from "./onboarding"

class MyProfile extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="My profile" />
        <h1>My profile</h1>
        <LocalForm>
          <Form.Row>
            <Col md={10} lg={8} xl={6}>
              <Form.Group>
                <Form.Label htmlFor="email">Email</Form.Label>
                <Control.text
                  className="form-control"
                  model=".email"
                  id="email"
                  value={this.props.user.email}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="firstName">First name</Form.Label>
                <Control.text
                  className="form-control"
                  model=".firstName"
                  id="firstName"
                  value={this.props.user.firstName}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="lastName">Last name</Form.Label>
                <Control.text
                  className="form-control"
                  model=".lastName"
                  id="lastName"
                  value={this.props.user.lastName}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="usageType">Usage type</Form.Label>
                <Control.text
                  className="form-control"
                  model=".usageType"
                  id="usageType"
                  value={(() => {
                    const usage = USAGE_TYPES.find(
                      usage => usage.name === this.props.user.usageType,
                    )
                    return usage ? usage.label : ""
                  })()}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="interests">Interests</Form.Label>
                <Control.text
                  className="form-control"
                  model=".interests"
                  id="interests"
                  value={INTERESTS.filter(interest =>
                    this.props.user.interests.includes(interest.name),
                  )
                    .map(interest => interest.label)
                    .join(", ")}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </LocalForm>
      </Layout>
    )
  }
}

export default expectAuthenticationState({ connected: true, onboarded: true })(
  connect(({ user }) => ({
    user,
  }))(MyProfile),
)
